<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNav></HeaderNav>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <BreadcrumbBlogDetail BreadcrumbProductDetailTitle="Blog Detail" breadcrumbName="Blog Detail"></BreadcrumbBlogDetail>

        </div>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>

// import BlogSlider from '@/components/BlogSlider.vue'
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ContactSection from '@/components/ContactSection.vue'
import FooterSection from '@/components/FooterSection.vue'
import BreadcrumbBlogDetail from '@/components/BreadcrumbBlogDetail.vue'
import { Disqus } from 'vue-disqus'
export default {
  components: {
    HeaderNav,
    Breadcrumb,
    FooterSection,
    Disqus,
    BannerTwo,
    BreadcrumbBlogDetail,
    ContactSection
  },
}

</script>