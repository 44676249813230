<template>
<div>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h1 class="breadcrumb-detail">{{ blog.title }}</h1>
                        <h6 class="breadcrumb-title">{{ moment(blog.created_at).format('DD MMMM YYYY') }}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row" ref="blogDetail">
            <div class="col-lg-10 col-md-12">
                <div class="content">
                    <div class="row paragraph" ref="descriptionBox">
                        <div style="text-align: left;" v-html="blog.description" />
                    </div>
                </div>
            </div>
            <div class="screen-container">
                <div class="row" ref="descriptionBox2">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="section-area">
                        <!-- <h2 class="section-desc">Blog</h2> -->
                        <p class="section-title">Related Article</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 desktop">
                        <swiper class="swiper-container screen-slider"
                        :breakpoints="swiperOptions.breakpoints"
                        :navigation="swiperOptions.navigation"
                        @swiper="onSwiper"
                        >
                            <swiper-slide  v-for="(slider, sliders) in sliders" :key="sliders" class="testimonial-item-2">
                                <div class="testimonial-content">
                                    <router-link :to="`/blog-detail/${slider.slug}`" class="">
                                        <img :src="`${slider.image ? imageUrl + slider.image : 'images/news/news-3.png'}`" alt="Image News">
                                    </router-link>
                                    <div class="testimonial-user-info">
                                        <div class="user-content">
                                            <h2 class="user-title">{{ slider.title }}</h2>
                                            <p>{{ moment(slider.created_at).format('DD MMMM YYYY') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mobile">
                        <div class="row feature-slider-2 mt-n30">
                            <div v-for="(order, index) in sliders" :key="index" class="col-lg-6 col-sm-12 mt-30">
                                <router-link :to="`/blog-detail/${order.slug}`" class="feature-icon">
                                    <img :src="`${order.image ? imageUrl + order.image : 'images/news/news-3.png'}`" alt="Image News">
                                </router-link>
                                <div class="feature-content">
                                    <h5 class="title">{{order.title}}</h5>
                                    <p>23 November 2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    class="desktop"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    v-if="descLength > 10000"
    :style="{  height: 'calc( ' + descriptionHeight + 'px + 2000px )' }"
    class="desktop">
    </div>
    <div style="background-color: #F3F6FF;"
    v-if="descLength <= 10000"
    :style="{  height: 'calc( ' + descriptionHeight + 'px - 700px )' }"
    class="desktop">
    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="cover"
    :style="{  height: '700px' }"
    class="desktop"
    >
    </video-background>

    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    v-if="descLength > 5500"
    :style="{  height: 'calc( ' + descriptionHeight + 'px + 525px )' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile">
    </div>
    <div style="background-color: #F3F6FF;"
    v-if="descLength <= 5500"
    :style="{  height: 'calc( ' + descriptionHeight + 'px + 525px )' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile">
    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="fill"
    :style="{  height: '700px' }"
    class="hero-area hero-bg hero-style-1 hero-width mobile"
    >
    </video-background>
</div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import { Swiper, SwiperSlide } from 'swiper/vue'
import moment from 'moment'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
    components: { 
        Swiper,
        SwiperSlide,
        VideoBackground
    },
    props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
    data () {
        return {
            introBg: 'images/breadcrumb/bg/bg.png',
            detailBg: 'images/breadcrumb/bg/detail-bg2.png',
            imageUrl: 'https://dev.api.the-netwerk.com/storage/app/',
            blog: {},
            blogs: {},
            descLength: 0,
            slug: this.$route.params.slug,
            paragraph: [],
            moment,
            title: '',
            swiperOptions: {
                breakpoints: {
                320: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                992: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 3
                }
                },
                navigation: {
                nextEl: '.screen-button-next',
                prevEl: '.screen-button-prev'
                }
            },
            descriptionHeight: 0,
            blogRelated: [],
        }
    },
    setup () {
        const route = useRoute()
        const store = useStore()

        async function getBlog () {
            const response = await axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog/${route.params.slug}`)
            const data = {
                title: 'Riset AI | Blog Detail',
                description: response.data.data.description,
                slug: response.data.data.slug,
            }
            store.commit('setState', data)
        }

        getBlog()
        useMeta({
            title: `${store.state.title}`,
            metas: [
                { hid: 'description', name: 'description', content: `${store.state.description}` },
                { hid: 'keywords', name: 'keywords', content: `${store.state.description}` },
                { hid: 'og:title', property: 'og:title', content: `${store.state.title}` },
                { hid: 'og:description', property: 'og:description', content: `${store.state.description}` },
                { hid: 'og:url', property: 'og:url', content: `https:/risetai.the-netwerk.com/${route.path}` },
                { hid: 'og:type', property: 'og:type', content: 'website' },
                { hid: 'og:site_name', property: 'og:site_name', content: 'Riset AI' },
            ]
        })
        // const response = axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog/${route.params.slug}`)
    },
    computed: {
        sliders () {
            return this.blogRelated.slice(0, 3)
        },
        htmlReturningFn: function ()  {
            return `there are <strong>BOLD</strong> words in this text`
        }
    },
    async mounted () {
        await this.getData()
        this.descriptionHeight = this.$refs.descriptionBox.offsetHeight
    },
    methods: {
        onSwiper (swiper) {
            this.swiper = swiper
        },
        async getData() {
            await this.axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog/${this.$route.params.slug}`).then(res => {
                this.blog = res.data.data
                this.title = res.data.data.title
                this.descLength = res.data.data.description.length
            })
            this.axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog`).then(res => {
                this.blogRelated = res.data.data
            })
        },
    }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
    position: relative;
    z-index: 1;
}
.hero-width{
    width: 100%;
    display: flex;
    z-index: -1;
}
.ql-align-center {
    text-align: center;
}
.breadcrumb-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    margin-top: 8px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */
    margin-block: 1px;

    text-align: center;
    letter-spacing: 0.03em;
}
.btn.purple-color {
    background-color: transparent;
    color: #000000;
    border-style: solid;
    border-color: rgb(39, 39, 39);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: -50px;
    z-index: 100;
}

.content {
    background-color: white;
}

.display-flex div {
    padding: 12px 28px;
    z-index: 100;
    border-radius: 12px;
}

.display-flex span {
    padding: 15px 15px;
}

.paragraph p{
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    text-align: justify;
    align-items: center;
    text-indent: 24px;
    white-space: pre-wrap;
}

.paragraph img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: auto;
    margin: 30px auto;
}
.testimonial-content img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 0px;
}
.testimonial-content a :hover {
    opacity: 0.7;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
.testimonial-content :hover {
    cursor: pointer;
}
.section-desc {
    color: #8D1245;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 42px;
    max-width: 610px;
    margin-bottom: 0px;
    margin-top: 40px;
}
.user-title {
    text-align: center;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
}
.screen-container {
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
.section-title {
    color: #25376F;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.03em;
}
.testimonial-item-2 {
    display: flex;
    width: 100%;
    padding: 0px 0px !important;
}
.testimonial-user-info {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
}
.user-content {
    margin-left: 0px;
    text-align: center;
}
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .hero-width{
        width: 100%;
    }

    .mobile {
        display: block;
    }
    
    .desktop {
        display: none;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -40px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        padding: 6px 8px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 30%;
        padding: 5px 5px;
    }
    .feature-icon img{
        width: 100%;
    }
}
</style>
